import COLORS from './colors';

const GLOBAL = {
  BASE_FONT_COLOR: COLORS.GREY_TEN,
  BASE_FONT_FAMILY: 'Montserrat, sans-serif',
  BRAND_FONT_FAMILY: 'Circular-Black, sans-serif',
  BRAND_FONT_FAMILY_SECOND: 'Circular-Book, sans-serif',
  BASE_FONT_SIZE: '16px',
  BASE_LINE_HEIGHT: '1.44',
  HEADER_HEIGHT: ['62px', '76px', '94px'],
};

export default GLOBAL;
