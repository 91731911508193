import styled, { css } from 'styled-components';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const H5Styles = css`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  font-size: ${FONT_SIZE.MEDIUM};
  letter-spacing: 0;
  line-height: ${LINE_HEIGHT.HEADING.MEDIUM};
  font-weight: ${FONT_WEIGHT.LIGHT};
  margin: 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.LARGE};
    line-height: ${LINE_HEIGHT.HEADING.LARGE};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.X_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.X_LARGE};
  }
`;

const H5 = styled.h5`
  ${H5Styles}
`;

export { H5, H5Styles };
