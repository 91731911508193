/**
 * Used for global styles, all other styles are declared at
 * the component level
 */
import React from 'react';

import SpacingStyles from './spacing-styles';
import TypographyStyles from './typography-styles';

const GlobalCSS = () => (
  <>
    <SpacingStyles />
    <TypographyStyles />
  </>
);

export default GlobalCSS;
