import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import NextApp from 'next/app';
import React from 'react';
import Router from 'next/router';

import GlobalCSS from '../components/global-css';
import GoogleTagManager from '../components/google-tag-manager';
import muiTheme from '../constants/mui-theme';
import { logPageViewEvent } from '../utils/tracking';
import styledTheme from '../constants/styled-theme';

class App extends NextApp {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    this.setupRouteChangeHandler();
  }

  setupRouteChangeHandler = () => {
    const handleRouteChange = (url) => {
      logPageViewEvent(url);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <ThemeProvider theme={styledTheme}>
        <MuiThemeProvider theme={muiTheme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <GlobalCSS />
          <GoogleTagManager />
          <Component {...pageProps} {...this.state} />
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

export default App;
