import buildKeysObject from '../utils/build-keys-object';

const BODY_STYLES = {
  all: { label: 'All types', imageUrl: '/images/all.jpg' },
  hatch: { label: 'Hatch & Small Cars', imageUrl: '/images/hatch.jpg' },
  sedans: { label: 'Sedans & wagons', imageUrl: '/images/sedans.jpg' },
  suvs: { label: 'SUVs & 4WDs', imageUrl: '/images/suvs.jpg' },
  sport: { label: 'Sport', imageUrl: '/images/sport.jpg' },
  peopleMovers: { label: 'People movers', imageUrl: '/images/people-movers.jpg' },
  commercial: { label: 'Utes & vans', imageUrl: '/images/commercial.jpg' },
};

const BODY_STYLE_KEYS = buildKeysObject(BODY_STYLES);

export { BODY_STYLES, BODY_STYLE_KEYS };
