const SECTION_HANDLES = {
  VEHICLES: 'vehicles',
  TAC_SAFETY_FEATURES: 'tacSafetyFeatures',
  REDBOOK_SAFETY_FEATURES: 'redbookSafetyFeatures',
  ANCAP_TEST_RECORDS: 'ancapTestRecords',
  HOME: 'home',
  CONTACT_US: 'contactUs',
  CONTENT_PAGES: 'contentPages',
  PRIVACY_POLICY: 'privacyPolicy',
  RATINGS: 'ratings',
  SAFETY_FEATURES: 'safetyFeatures',
  TERMS: 'terms',
  RESULTS: 'results',
};

export default SECTION_HANDLES;
