const CATEGORY_SLUGS = {
  ACTIVE_SAFETY_FEATURES: 'active-safety-features',
  ADULT_OCCUPANT_PROTECTION: 'adult-occupant-protection',
  CHILD_OCCUPANT_PROTECTION: 'child-occupant-protection',
  CRASH_PROTECTION_FOR_ADULTS: 'crash-protection-for-adults',
  CRASH_PROTECTION_FOR_KIDS: 'crash-protection-for-kids',
  CRASH_PROTECTION_FOR_OTHER_ROAD_USERS: 'crash-protection-for-other-road-users',
  PEDESTRIAN_PROTECTION: 'pedestrian-protection',
  SAFETY_ASSIST: 'safety-assist',
  VULNERABLE_ROAD_USER_PROTECTION: 'vulnerable-road-user-protection',
};

export default CATEGORY_SLUGS;
