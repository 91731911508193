import styled, { css } from 'styled-components';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const H3Styles = css`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  font-size: ${FONT_SIZE.X_LARGE};
  line-height: ${LINE_HEIGHT.HEADING.X_LARGE};
  font-weight: ${FONT_WEIGHT.LIGHT};
  letter-spacing: 0;
  margin: 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.XX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XX_LARGE};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.XXX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XXX_LARGE};
  }
`;

const H3 = styled.h3`
  ${H3Styles}
`;

export { H3, H3Styles };
