const FONT_SIZE = {
  XXXXX_LARGE: '48px',
  XXXX_LARGE: '36px',
  XXX_LARGE: '30px',
  XX_LARGE: '24px',
  X_LARGE: '20px',
  LARGE: '18px',
  MEDIUM: '16px',
  MEDIUM_SMALL: '14px',
  SMALL: '13px',
  X_SMALL: '12px',
  XX_SMALL: '11px',
  XXX_SMALL: '9px',
};

export default FONT_SIZE;
