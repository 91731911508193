import URLS from './urls';

const LINKS = {
  ANCAP: 'https://www.ancap.com.au/',
  TAC: 'https://www.tac.vic.gov.au',
  VICTORIA_GOVERNMENT: 'https://www.vic.gov.au',
  PRIMARY: [
    { title: 'Find a car', url: URLS.HOME },
    { title: 'Safety features', url: URLS.SAFETY_FEATURES_LISTING },
  ],
  SECONDARY: [
    { title: 'Ratings', url: URLS.RATINGS },
    { title: 'Contact', url: URLS.CONTACT_US },
  ],
  TERMS_AND_PRIVACY: [
    { title: 'Privacy Policy', url: URLS.PRIVACY_POLICY },
    { title: 'Terms of use', url: URLS.TERMS_OF_USE },
    { title: 'Sitemap', url: URLS.SITEMAP },
  ],
};

export default LINKS;
