import _ from 'lodash';
import { createGlobalStyle } from 'styled-components';

const createSpacing = (props) => {
  const { BREAKPOINTS, REM } = props.theme;
  let rems = ``;

  _.forEach(_.toPairs(REM), ([breakpoint, size], index) => {
    if (index === 0) {
      rems += `
      font-size: ${size};
    `;
    } else {
      rems += `
      @media (min-width: ${BREAKPOINTS[breakpoint]}px) {
        font-size: ${size};
      }
    `;
    }
  });

  return `
    html {
      ${rems}
    }
  }`;
};

const SpacingStyles = createGlobalStyle`
  ${(props) => createSpacing(props)};
`;

export default SpacingStyles;
