import SPACE from './space';
import BREAKPOINTS from './breakpoints';
import REM from './rem';
import MEDIA_QUERIES from './media-queries';

const theme = {
  SPACE,
  BREAKPOINTS,
  REM,
  MEDIA_QUERIES,
};

export default theme;
