const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  BRAND_BLUE_ONE: '#f2faff',
  BRAND_BLUE_TWO: '#d0ebfc',
  BRAND_BLUE_THREE: '#78c8f5',
  BRAND_BLUE_FOUR: '#47b3ee',
  BRAND_BLUE_FIVE: '#1ea2e5',
  BRAND_BLUE_SIX: '#1597dd',
  BRAND_BLUE_SEVEN: '#0182cf',
  BRAND_BLUE_EIGHT: '#0071c1',
  BRAND_BLUE_NINE: '#015fb0',
  BRAND_BLUE_TEN: '#004d9c',
  BRAND_BLUE_ELEVEN: '#003d87',
  BRAND_BLUE_TWELVE: '#0a295a',
  BRAND_NAVY_ONE: '#ebf9ff',
  BRAND_NAVY_TWO: '#d1edfa',
  BRAND_NAVY_THREE: '#9cd4ed',
  BRAND_NAVY_FOUR: '#62b5da',
  BRAND_NAVY_FIVE: '#329ccb',
  BRAND_NAVY_SIX: '#2893c2',
  BRAND_NAVY_SEVEN: '#1384b4',
  BRAND_NAVY_EIGHT: '#0a74a2',
  BRAND_NAVY_NINE: '#0d6084',
  BRAND_NAVY_TEN: '#0f465e',
  BRAND_NAVY_ELEVEN: '#113646',
  BRAND_YELLOW_ONE: '#fffff5',
  BRAND_YELLOW_TWO: '#ffffd5',
  BRAND_YELLOW_THREE: '#ffffb5',
  BRAND_YELLOW_FOUR: '#fffe80',
  BRAND_YELLOW_FIVE: '#fff84f',
  BRAND_YELLOW_SIX: '#ffee24',
  BRAND_YELLOW_SEVEN: '#ffd414',
  BRAND_YELLOW_EIGHT: '#f6bf00',
  BRAND_YELLOW_NINE: '#ec9f02',
  BRAND_YELLOW_TEN: '#e17f09',
  BRAND_YELLOW_ELEVEN: '#d46511',
  GREY_ONE: '#fcfcfc',
  GREY_TWO: '#f9f9f9',
  GREY_THREE: '#f4f4f4',
  GREY_FOUR: '#e7e7e7',
  GREY_FIVE: '#cecece',
  GREY_SIX: '#a9a9a9',
  GREY_SEVEN: '#818181',
  GREY_EIGHT: '#5c5c5c',
  GREY_NINE: '#444444',
  GREY_TEN: '#2c2c2c',
  GREY_ELEVEN: '#1e1e1e',
  DANGER_RED_ONE: '#fff2f2',
  DANGER_RED_TWO: '#fed7d5',
  DANGER_RED_THREE: '#febeb9',
  DANGER_RED_FOUR: '#fb8f82',
  DANGER_RED_FIVE: '#f66951',
  DANGER_RED_SIX: '#ef4e2a',
  DANGER_RED_SEVEN: '#e63c0b',
  DANGER_RED_EIGHT: '#d92600',
  DANGER_RED_NINE: '#c91a00',
  DANGER_RED_TEN: '#b41000',
  DANGER_RED_ELEVEN: '#9e0600',
  SUCCESS_GREEN_ONE: '#f7fcf3',
  SUCCESS_GREEN_TWO: '#ecf8e1',
  SUCCESS_GREEN_THREE: '#d6f4b0',
  SUCCESS_GREEN_FOUR: '#bde976',
  SUCCESS_GREEN_FIVE: '#a8da40',
  SUCCESS_GREEN_SIX: '#94c316',
  SUCCESS_GREEN_SEVEN: '#78a601',
  SUCCESS_GREEN_EIGHT: '#5c9100',
  SUCCESS_GREEN_NINE: '#427a05',
  SUCCESS_GREEN_TEN: '#256700',
  SUCCESS_GREEN_ELEVEN: '#1f5401',
};

export default COLORS;
