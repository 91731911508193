/**
 * The naming of these is a bit weird - this is because they correspond directly to the FONT-SIZE constants.
 *
 * These should be hard-coded in the type constants files (such as h6.js), and should never need to be overridden manually.
 *
 * Examples:
 * When a heading style (H3, H6, etc) uses FONT_SIZE.XX_LARGE, it should be using LINE_HEIGHT.HEADING.XX_LARGE.
 * When a body style (P, Small, SectionHeading, etc) uses FONT_SIZE.X_SMALL, it should be using LINE_HEIGHT.BODY.X_SMALL
 */

const HEADING = {
  XXXXX_LARGE: '52px',
  XXXX_LARGE: '40px',
  XXX_LARGE: '36px',
  XX_LARGE: '30px',
  X_LARGE: '22px',
  LARGE: '21px',
  MEDIUM: '20px',
  MEDIUM_SMALL: '18px',
  X_SMALL: '16px',
};

const BODY = {
  X_LARGE: '30px',
  LARGE: '26px',
  MEDIUM: '22px',
  SMALL: '18px',
  X_SMALL: '16px',
  XX_SMALL: '16px',
};

export default { HEADING, BODY };
