const BREAKPOINTS = [0, 576, 768, 992, 1415];

/* eslint-disable prefer-destructuring */
BREAKPOINTS.XS = BREAKPOINTS[0]; // 0
BREAKPOINTS.SM = BREAKPOINTS[1]; // 576px
BREAKPOINTS.MD = BREAKPOINTS[2]; // 768px
BREAKPOINTS.LG = BREAKPOINTS[3]; // 992px
BREAKPOINTS.XL = BREAKPOINTS[4]; // 1415px
/* eslint-enable */

export default BREAKPOINTS;
