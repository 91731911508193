const SPACE = ['0.5rem', '1rem', '2rem', '3rem', '4rem'];

/* eslint-disable prefer-destructuring */
SPACE.X0 = SPACE[0]; // .5rem
SPACE.X1 = SPACE[1]; // 1rem
SPACE.X2 = SPACE[2]; // 2rem
SPACE.X3 = SPACE[3]; // 3rem
SPACE.X4 = SPACE[4]; // 4rem
/* eslint-enable */

export default SPACE;
