import { createGlobalStyle } from 'styled-components';

import { GLOBAL, FONT_WEIGHT } from '../../constants';
import { H1Styles, H2Styles, H3Styles, H4Styles, H5Styles, H6Styles, PStyles } from '../type';

const circularStdBlackWoff = '/fonts/lineto-circular-pro-black.woff';
const circularStdBookWoff = '/fonts/lineto-circular-pro-book.woff';
const montserratRegularWoff = '/fonts/montserrat-v13-latin-regular.woff';
const montserratRegularWoff2 = '/fonts/montserrat-v13-latin-regular.woff2';
const montserratRegularItalicWoff = '/fonts/montserrat-v13-latin-italic.woff';
const montserratRegularItalicWoff2 = '/fonts/montserrat-v13-latin-italic.woff2';
const montserratBoldWoff = '/fonts/montserrat-v13-latin-700.woff';
const montserratBoldWoff2 = '/fonts/montserrat-v13-latin-700.woff2';

const TypographyStyles = createGlobalStyle`
  @font-face {
    font-display: fallback;
    font-family: 'Circular-Black';
    font-style: normal;
    font-weight: ${FONT_WEIGHT.LIGHT};
    src: local('Circular Black'), local('Circular-Black'), url(${circularStdBlackWoff}) format("woff");
  }

  @font-face {
    font-display: fallback;
    font-family: 'Circular-Book';
    font-style: normal;
    font-weight: ${FONT_WEIGHT.LIGHT};
    src: local('Circular Book'), local('Circular-Book'), url(${circularStdBookWoff}) format("woff");
  }

  @font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: ${FONT_WEIGHT.LIGHT};
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(${montserratRegularWoff2}) format('woff2'), url(${montserratRegularWoff}) format('woff');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: ${FONT_WEIGHT.LIGHT};
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(${montserratRegularItalicWoff2}) format('woff2'), url(${montserratRegularItalicWoff}) format('woff');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: ${FONT_WEIGHT.BOLD};
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(${montserratBoldWoff2}) format('woff2'), url(${montserratBoldWoff}) format('woff');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: ${FONT_WEIGHT.BOLD};
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(${montserratBoldWoff2}) format('woff2'), url(${montserratBoldWoff}) format('woff');
  }

  html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: ${GLOBAL.baseFontFamily};
    color: ${GLOBAL.baseFontColor};
  }

  body {
    ${PStyles};
    margin: 0;
  }

  h1 {
    ${H1Styles};
  }

  h2 {
    ${H2Styles};
  }

  h3 {
    ${H3Styles};
  }

  h4 {
    ${H4Styles};
  }

  h5 {
    ${H5Styles};
  }

  h6 {
    ${H6Styles};
  }

  p {
    ${PStyles};
  }

  b,
  strong {
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  i,
  em {
    font-style: italic;
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href*="mailto:"] {
      word-wrap: break-word;
    }
  }
`;

export default TypographyStyles;
