import { Component } from 'react';

import { initGoogleTagManager } from '../../utils/tracking';

const getGtagId = () => process.env.GOOGLE_TAG_MANAGER_ID;

class GoogleTagManager extends Component {
  componentDidMount() {
    initGoogleTagManager(getGtagId());
  }

  render() {
    return null;
  }
}

export default GoogleTagManager;
