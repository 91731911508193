import TagManager from 'react-gtm-module';

/**
 * initGoogleTagManager - Initialise Google Tag Manager with the correct ID
 * @param {string} trackingID
 */
const isDev = process.env.NODE_ENV === 'development';
const { GTM_AUTH_DEV } = process.env;
const { GTM_AUTH_LIVE } = process.env;
const { GTM_PREVIEW_DEV } = process.env;
const { GTM_PREVIEW_LIVE } = process.env;

const initGoogleTagManager = (TAG_MANAGER_ID) => {
  if (TAG_MANAGER_ID) {
    TagManager.initialize({
      gtmId: TAG_MANAGER_ID,
      auth: isDev ? GTM_AUTH_DEV : GTM_AUTH_LIVE,
      preview: isDev ? GTM_PREVIEW_DEV : GTM_PREVIEW_LIVE,
    });
  }
};

/**
 * logTrackingEvent - Log custom tracking event
 * @param {object} eventObject
 */
const logTrackingEvent = (eventObject) => {
  const isAnalyticsInitialized = window.dataLayer;

  if (isAnalyticsInitialized) {
    window.dataLayer.push(eventObject);
  }
};

/**
 * logPageViewEvent - Log custom pageview event
 * @param {string} current page url
 */
const logPageViewEvent = (url) => {
  const isAnalyticsInitialized = window.dataLayer;

  if (isAnalyticsInitialized) {
    window.dataLayer.push({
      event: 'pageview', // This event is setup in Tag Manager under "Triggers" and then linked to GA under "tags" in "Universal - Page View - All Pages"
      page: url,
    });
  }
};

export { initGoogleTagManager, logTrackingEvent, logPageViewEvent };
