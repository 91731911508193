import styled, { css } from 'styled-components';

import { GLOBAL, FONT_WEIGHT } from '../../constants';

const AStyles = css`
  font-family: ${GLOBAL.BASE_FONT_FAMILY};
  font-size: inherit;
  line-height: inherit;
  font-weight: ${FONT_WEIGHT.REGULAR};
  letter-spacing: normal;
  margin: 0;
`;

const A = styled.a`
  ${AStyles}
`;

export { A, AStyles };
